import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { signUp, signIn, signUpWithGoogle, signUpWithMicrosoft } from "../lib/auth";
import { useUser } from "../contexts/userContext";
import { motion } from "framer-motion";
import AnimatedSection from "../Components/AnimatedSection";
import BackdropAnimation from "../Components/BackdropAnimation";
import useAuth from "../hooks/useAuth";

import { LuArrowLeft } from "react-icons/lu";

const Authwall = ({ onAuthSuccess }) => { // No need to rename here
   const isAuthenticated = useAuth();
    const navigate = useNavigate();
   const [name, setName] = useState(null);
   const [email, setEmail] = useState(null);
   const [password, setPassword] = useState(null);
   const { user, setUser } = useUser();
   const [isLogin, setIsLogin] = useState(false);

   const toggleAuthView = (e) => {
     e.preventDefault();
     setIsLogin((prev) => !prev);
   };

   const openOAuthPopup = (provider) => {

      if (isAuthenticated) {
         onAuthSuccess();
         return;
     } 

      const authUrl = `${process.env.REACT_APP_BACKEND_URL}/auth/${provider}/popup-callback`;
      const popup = window.open(authUrl, "_blank", "width=500,height=600");
  
      if (!popup) return alert("Popup blocked!");
  
      const handleAuthMessage = async (event) => {
          if (event.origin !== process.env.REACT_APP_BACKEND_URL || !event.data.success) return;
  
          const { accessToken } = event.data; // Get token from postMessage
          if (!accessToken) return;
          localStorage.setItem("accessToken", accessToken);
  
          const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/me`, {
              method: "GET",
              headers: { Authorization: `Bearer ${accessToken}` },
          });
  
          if (res.ok) {
              setUser(await res.json());
              clearInterval(authCheckInterval); // Stop checking once authenticated
              onAuthSuccess();
          }
          window.removeEventListener("message", handleAuthMessage);
      };
  
      window.addEventListener("message", handleAuthMessage);
  
      let elapsedTime = 0;
      const authCheckInterval = setInterval(async () => {
          if (isAuthenticated) {
              clearInterval(authCheckInterval);
              window.removeEventListener("message", handleAuthMessage);
              onAuthSuccess();
              return;
          }
  
          elapsedTime += 1000;
          if (elapsedTime >= 30000 || popup.closed) { // Stop checking after 30 seconds or if popup closes
              clearInterval(authCheckInterval);
              window.removeEventListener("message", handleAuthMessage);
          }
      }, 1000);
  };

const handleSubmit = async (e) => {
   e.preventDefault(); // Prevent page reload

   if (isAuthenticated) {
       onAuthSuccess();
       return;
   }

   if (name && email && password) {
       console.log(name, email, password);
       const { error, accessToken, refreshToken } = await signUp({ name, email, password });
       if (error) {
           alert(error);
           console.log(error);
           return;
       }
       const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/me`, {
           method: "GET",
           headers: {
               Authorization: `Bearer ${accessToken}`,
           },
       });
       const { user } = await res.json();
       setUser(user);

       onAuthSuccess();
   } else {
       alert("Please fill all required fields");
   }
};

const handleSubmitLogin = async (e) => {
   e.preventDefault();

   if (isAuthenticated) {
       onAuthSuccess();
       return;
   }

   if (email && password) {
       const { error, accessToken, refreshToken } = await signIn({ email, password });
       if (error) {
           alert(error);
           return;
       }
       const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/me`, {
           method: 'GET',
           headers: {
               'Authorization': `Bearer ${accessToken}`
           }
       });
       const { user } = await res.json();
       setUser(user);
       onAuthSuccess();
   } else {
       alert('Please fill all required fields');
   }
};


  return (
    <div class="authwallclassmain">
        {!isLogin && 
        <div class="signupwall">
         <AnimatedSection>
                  <AnimatedSection delay={0.2}>
                     <div className="banner-form-sec mt-4">
                        <form className="d-flex align-items-center">
                           <div className="form-group">
                              <AnimatedSection delay={0.3}>
                                 <input
type="name"
                                    onChange={(e) => {
                                       setName(e.target.value);
                                    }}
                                    placeholder="Name"
                                    className="form-control nameauthlock"
                                 />
                              </AnimatedSection>
                              <AnimatedSection delay={0.4}>
                                 <input
type="email"
                                    onChange={(e) => {
                                       setEmail(e.target.value);
                                    }}
                                    placeholder="example@gmail.com"
                                    className="form-control emailauthlock mt-2"
                                       autocapitalize="off"
                                          inputmode="email"
                                             autocomplete="email"
                                 />
                              </AnimatedSection>
                              <AnimatedSection delay={0.5}>
                                 <input
                                    type="password"
                                    onChange={(e) => {
                                       setPassword(e.target.value);
                                    }}
                                    placeholder="Password"
                                    className="form-control mt-2"
                                 />
                              </AnimatedSection>
                              <AnimatedSection delay={0.6} triggerOnMount>
                                 <button
                                    onClick={(e) => {
                                       handleSubmit(e);
                                    }}
                                    className="btn authlockbtn"
                                 >
                                    Continue
                                 </button>
                              </AnimatedSection>
                           </div>
                        </form>
                     </div>
                  </AnimatedSection>
                  <AnimatedSection delay={0.7} triggerOnMount>
                     <div className="or-sec-login ordiv mb-2 mt-2">OR</div>
                  </AnimatedSection>
                     <div className="banner-form-sec mt-4">
                        <form className="d-flex align-items-center">
                           <div className="form-group ">
                              <AnimatedSection delay={0.8} triggerOnMount>
                                       <Link
                                 onClick={(e) => {
                                    e.preventDefault();
                                    openOAuthPopup("google");
                                  }}
                                 className="form-control btn btn-border mx-1 mb-3 nohovereffect"
                              >
                                 <img src="/images/google.png" alt="" />
                                 Continue With Google
                              </Link></AnimatedSection>
                              <AnimatedSection delay={0.9} triggerOnMount>
                              <Link
                                 onClick={(e) => {
                                    e.preventDefault();
                                    openOAuthPopup("microsoft");
                                  }}
                                 to=""
                                 className="form-control btn btn-border mx-1 mb-4 nohovereffect"
                              >
                                 <img src="/images/microsoft.png" alt="" />
                                 Continue With Microsoft
                              </Link></AnimatedSection>
                           </div>
                        </form>
                     </div>
                  
                  {/* <AnimatedSection delay={0.2}> */}
                  <p className="signup-text signup mb-3 authlocklogin">
                     Already have an account?{" "}
                     <Link to="/login" className="linknostyle authlockloginclick" onClick={toggleAuthView}>
                        Login here
                     </Link>
                  </p>
                  {/* </AnimatedSection> */}
         </AnimatedSection>
         </div>}
         {isLogin &&
         <div class="signinwall">
         <AnimatedSection>
                  <AnimatedSection delay={0.2}>
                  <div className="banner-form-sec mt-4">
                        <form className="d-flex align-items-center">
                           <div className="form-group d-flex flex-column signin-form-fields">
                              <AnimatedSection delay={0.2}><input type="email" onChange={(e)=>{setEmail(e.target.value)}} placeholder="example@gmail.com" className="form-control" autocapitalize="off" inputmode="email" autocomplete="email"/></AnimatedSection>
                              <AnimatedSection delay={0.3}><input type="password" onChange={(e)=>{setPassword(e.target.value)}} placeholder="Password" className="form-control mt-2" /></AnimatedSection>
                              <AnimatedSection delay={0.4}><button onClick={(e)=>{handleSubmitLogin(e)}} className="btn authlockbtn">Continue</button></AnimatedSection>
                           </div>
                        </form>
                     </div>
                  </AnimatedSection>
                  <AnimatedSection delay={0.7} triggerOnMount>
                     <div className="or-sec-login ordiv mb-2 mt-2">OR</div>
                  </AnimatedSection>
                     <div className="banner-form-sec mt-4">
                        <form className="d-flex align-items-center">
                           <div className="form-group ">
                              <AnimatedSection delay={0.8} triggerOnMount>
                                       <Link
                                 onClick={(e) => {
                                    e.preventDefault();
                                    openOAuthPopup("google");
                                  }}
                                 className="form-control btn btn-border mx-1 mb-3 nohovereffect"
                              >
                                 <img src="/images/google.png" alt="" />
                                 Continue With Google
                              </Link></AnimatedSection>
                              <AnimatedSection delay={0.9} triggerOnMount>
                              <Link
                                 onClick={(e) => {
                                    e.preventDefault();
                                    openOAuthPopup("microsoft");
                                  }}
                                 className="form-control btn btn-border mx-1 mb-4 nohovereffect"
                              >
                                 <img src="/images/microsoft.png" alt="" />
                                 Continue With Microsoft
                              </Link></AnimatedSection>
                           </div>
                        </form>
                     </div>
                  
                  {/* <AnimatedSection delay={0.2}> */}
                  <p className="signup-text signup mb-3 authlocklogin">
                     Don't have an account?{" "}
                     <Link className="linknostyle authlockloginclick" onClick={toggleAuthView}>
                        Register here
                     </Link>
                  </p>
                  {/* </AnimatedSection> */}
         </AnimatedSection>
         </div>}
      </div>
  );
};

export default Authwall;
