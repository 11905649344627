import React, { useState, useEffect, useRef } from "react";
import "../Style/generatetool.css";
import AnimatedSection from "../Components/AnimatedSection";
import Authwall from "../Components/Authwall";
import { AuthFetch, AuthFetch2, refresh, signUpWithGoogle, signUpWithMicrosoft } from "../lib/auth";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import BackdropAnimation from "../Components/BackdropAnimation";
import BackdropGlow from "../Components/BackdropGlow";
import { useUser } from "../contexts/userContext";



function Homepage() {
   const { userPlan } = useAuth();
   const isAuthenticated = useAuth();
   const navigate = useNavigate()
   const [url, setUrl] = useState(null);
    const [mockup, setMockup] = useState({
        url: "https://www.brandname.com",
        headline: "Your Brandname - Enter Your URL Below",
        description: "Enter a Website and instantly Generate PPC search ads. Experience how Easy it is to Launch Ads with AI—no expertise required.",
        display_url: "yourwebsite.com",
        sitelinks: [
            { title: "Try for Yourself", description: "Generate a PPC ad for your website now." },
            { title: "It's Completely Free", description: "See how AI makes ad creation effortless." },
            { title: "Your Page here", description: "Let's customize your journey with us." },
            { title: "Launch in 5 Min", description: "Try for yourself by entering a URL above." },
        ],
    });

    const formatUrl = (url) => {
        // Remove protocol (https:// or http://)
        let formattedUrl = url.replace(/(^\w+:|^)\/\//, '');
  
        // Remove 'www.' if it exists
        formattedUrl = formattedUrl.replace(/^www\./, '');
  
        // Remove everything after the first '.'
        formattedUrl = formattedUrl.split('.')[0];
  
        return formattedUrl;
     };

    const Mocksite = [
        { site_text: "Try for Yourself", sitePara: "Generate a PPC ad for your website now." },
        { site_text: "It's Completely Free", sitePara: "See how AI makes ad creation effortless." },
        { site_text: "Your Page here", sitePara: "Let's customize your journey with us." },
        { site_text: "Launch in 5 Minutes", sitePara: "Try for yourself by entering a URL above." },
    ];

    const [mockupLoading, setMockupLoading] = useState(false);
    const handleStartAndGenerateMockup = async () => {
        if (!url) {
            alert('Please fill valid url');
            return;
        }
    
        const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
        
        setIsStarted(true);
        setStepOneCompleted(true);
        handleScrollToMockup();
    
        try {
            setMockupLoading(true);
            const response = await AuthFetch2('campaign-gen/mockupGen', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    url: formattedUrl
                })
            });
    
            const data = await response.json();
            if (data?.message) {
                setMockupLoading(false);
                return alert(data?.message);
            }
    
            setMockup(data?.mockup);
            setMockupLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

     const [checkedItems, setCheckedItems] = useState({
        all: true,
        headline: true,
        descriptions: true,
        sitelinks: true,
        callout: true,
        snippets: true,
        sheetURL: true,
     });
    const [numberOfAds, setNumberOfAds] = useState(1);
    const [numberOfKeywords, setNumberOfKeywords] = useState(5);
    const [selectedMenu, setSelectedMenu] = useState('Headlines');
    const [campaignData, setCampaignData] = useState({
        "Broad search campaign": {
         menuItems: {
            Headlines: `- Capitalize for readability.\n- Engaging, to the point.\n- Aim for CTR and CTA.\n- 30 characters max.`,
            Descriptions: `- Capitalize for readability.\n- Clear, concise, actionable.\n- Focus on product value.\n- 90 characters max.`,
            Sitelinks: `- Capitalize for readability.\n- Name matches page intent.\n- Descriptions: 7-12 words max.\n- Provide clear final URLs.`,
            Snippets: `- Select predefined headers.\n- Each header: 4 relevant items.\n- Align with ad content.\n- Focus on clarity and engagement.`,
            Callouts: `- Capitalize for readability.\n- Highlight unique selling points.\n- Keep it concise, 5-20 characters.\n- Encourage clicks with impact.`,
            Keywords: "- Medium long-tail: 3-4 words each.\n- 1 keyword of 3 words and 2 of 4 words.\n- No hyphens & no consecutive long words.\n- Maximum 5 words per keyword."
         },
      },
    });
    const [campaignMode, setCampaignMode] = useState('Broad search campaign');
    const [showCustomInstructions, setShowCustomInstructions] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState({ campaign: false, principle: false });
    const maxInstructionsLength = 200;
    const glowClass =
      campaignMode === "Broad search campaign"
         ? "backdrop-elm-white"
         : campaignMode === "Brand name campaign"
            ? "backdrop-elm-white"
            : "backdrop-elm-white";
   const campaignModes = [
      "Brand name campaign",
      "Broad search campaign",
   ];

    const handleInstructionsChange = (e) => {
        const newInstructions = e.target.value;
        if (newInstructions.length <= maxInstructionsLength) {
            setCampaignData(prevData => ({
                ...prevData,
                [campaignMode]: {
                    ...prevData[campaignMode],
                    menuItems: {
                        ...prevData[campaignMode].menuItems,
                        [selectedMenu]: newInstructions
                    }
                }
            }));
        }
    };

    // State
    const [selectedLanguage, setSelectedLanguage] = useState("English");
    const [languageSelected, setLanguageSelected] = useState(false);

    // For the principle
    const [principle, setPrinciple] = useState("None");
    const [principleSelected, setPrincipleSelected] = useState(false);

    // Display logic
    const getDisplayLanguage = () => {
        // If the user has *never* manually chosen anything and the default is "English"
        if (!languageSelected && selectedLanguage === "English") {
            return "Language";
        }
        // Once the user *has* manually chosen "English" (or anything else), display it
        return selectedLanguage;
    };

    const getDisplayPrinciple = () => {
        // If the user has *never* manually chosen anything and the default is "None"
        if (!principleSelected && principle === "None") {
            return "Cialdini Principle";
        }
        return principle;
    };

    const toggleDropdown = (type) => {
        setDropdownOpen((prev) => ({
           campaign: type === "campaign" ? !prev.campaign : false,
           principle: type === "principle" ? !prev.principle : false,
        }));
     };
  
     const handleOptionSelect = (type, value, event) => {
        event.stopPropagation();
        if (type === "campaign") {
          setSelectedLanguage(value);
          setLanguageSelected(true); // Mark language as selected
        } else if (type === "principle") {
          setPrinciple(value);
          setPrincipleSelected(true); // Mark principle as selected
        }
        setDropdownOpen((prev) => ({
          ...prev,
          [type]: false,
        }));
      };
     useEffect(() => {
        const handleClickOutside = (event) => {
           if (
              !event.target.closest(".custom-dropdown.selectcm") &&
              !event.target.closest(".custom-dropdown.selectcp")
           ) {
              setDropdownOpen({ campaign: false, principle: false });
           }
        };
  
        document.addEventListener("click", handleClickOutside);
        return () => {
           document.removeEventListener("click", handleClickOutside);
        };
     }, []);

     const [extractLoading, setExtractLoading] = useState(false);
     const [extractFile, setExtracted] = useState(false);
     const { user } = useUser();

     const getValidInstruction = (field) => {
        return field.trim() === "" ? null : field;
     };
     const handleExtract = async () => {
        if (!url) return alert('Please fill valid url');
        const formattedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;

   // Minimal credit check using the user's credits (displayed in header as {user?.credits?.toLocaleString('de-DE')})
   if (user?.credits < totalCost) {
      alert(`Not enough credits. You have ${user.credits} credits, but you require ${totalCost} credits.`);
      return;
   }

        setExtractLoading(true);
        const accessToken = localStorage.getItem('accessToken')
        const { headline, descriptions, sitelinks, callout, snippets } = checkedItems
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
           method: 'POST',
           headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
           },
           body: JSON.stringify({
              url: formattedUrl, headline, descriptions,
              sitelinks, callout, snippets,
              count: numberOfAds, campaignMode, cialdini: principle === "Cialdini's principle" ? "none" : principle,
              countk: numberOfKeywords,
              specialInstructionsHeadlines: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Headlines
                    : campaignData["Broad search campaign"].menuItems.Headlines
              ),
              specialInstructionsDescriptions: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Descriptions
                    : campaignData["Broad search campaign"].menuItems.Descriptions
              ),
              specialInstructionsSitelinks: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Sitelinks
                    : campaignData["Broad search campaign"].menuItems.Sitelinks
              ),
              specialInstructionsSnippets: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Snippets
                    : campaignData["Broad search campaign"].menuItems.Snippets
              ),
              specialInstructionsCallouts: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Callouts
                    : campaignData["Broad search campaign"].menuItems.Callouts
              ),
              specialInstructionsKeywords: getValidInstruction(
                 campaignMode === "Brand name campaign"
                    ? campaignData["Brand name campaign"].menuItems.Keywords
                    : campaignData["Broad search campaign"].menuItems.Keywords
              ),
              language: selectedLanguage
           })
        });
  
        if (response.ok) {
           const blob = await response.blob();
           const url = window.URL.createObjectURL(blob);
  
           // Create an anchor element and trigger a download
           const a = document.createElement('a');
           a.href = url;
           a.download = 'campaign_template.xlsx'; // Set the filename for download
           document.body.appendChild(a);
           a.click();
  
           // Cleanup: Remove the link element and revoke the blob URL
           a.remove();
           window.URL.revokeObjectURL(url);
           setExtractLoading(false)
           setExtracted(true)
  
        } else if (response.status === 401) {
           const { accessToken: newAccessToken } = await refresh();
           const res = await fetch(`${process.env.REACT_APP_BACKEND_URL}/campaign-gen/extract`, {
              method: 'POST',
              headers: {
                 'Content-Type': 'application/json',
                 'Authorization': `Bearer ${newAccessToken}`
              },
              body: JSON.stringify({
                 url, language: selectedLanguage, headline, descriptions, sitelinks, callout, snippets
              })
           });
  
           if (res.ok) {
              const blob = await res.blob();
              const url = window.URL.createObjectURL(blob);
  
              // Create an anchor element and trigger a download
              const a = document.createElement('a');
              a.href = url;
              a.download = 'campaign_template.xlsx'; // Set the filename for download
              document.body.appendChild(a);
              a.click();
  
              // Cleanup: Remove the link element and revoke the blob URL
              a.remove();
              window.URL.revokeObjectURL(url);
              setExtractLoading(false)
           } else {
              const data = await response.json();
              alert(data.message);
              setExtractLoading(false)
           }
        } else {
           const data = await response.json();
           alert(data?.message)
           setExtractLoading(false)
        }
  
     }


     //COSTSSSSS
     const [totalCost, setTotalCost] = useState(250);

     const COSTS = {
        headline: 50,
        descriptions: 50,
        sitelinks: 50,
        callout: 50,
        snippets: 50,
     };


     const calculateTotalCost = () => {
        const fields = ['headline', 'descriptions', 'sitelinks', 'callout', 'snippets'];
        let cost = 0;
  
        // Safely iterate through fields and add their cost
        fields.forEach((field) => {
           if (checkedItems[field]) {
              cost += COSTS[field];
           }
        });
  
        // Safely parse `numberOfAds` or default to 1
        const adsCount = numberOfAds > 0 ? numberOfAds : 1;
  
        // Final cost calculation
        setTotalCost(cost * adsCount);
     };
  
     useEffect(() => {
        calculateTotalCost();
     }, []);
  
     useEffect(() => {
        calculateTotalCost();
     }, [numberOfAds, checkedItems]);


    const [isStarted, setIsStarted] = useState(false);
const [stepOneCompleted, setStepOneCompleted] = useState(false);
const [stepTwoCompleted, setStepTwoCompleted] = useState(false);
const [stepThreeCompleted, setStepThreeCompleted] = useState(false);
const [stepFourCompleted, setStepFourCompleted] = useState(false);
const [stepFiveCompleted, setStepFiveCompleted] = useState(false);
const [stepSixCompleted, setStepSixCompleted] = useState(false);
const [stepThreehalfCompleted, setStepThreehalfCompleted] = useState(false);
const [stepSixHalfCompleted, setStepSixHalfCompleted] = useState(false);


const handleContinueToSixHalf = () => {
    setStepSixHalfCompleted(true);
};

const handleContinue = () => {
    setStepTwoCompleted(true);
};

const handleContinueToFour = () => {
    setStepThreeCompleted(true);
};

const handleAuthSuccess = () => {
    setStepThreehalfCompleted(true)
 };

const handleContinueToFive = () => {
    setStepFourCompleted(true);
};
const handleContinueToSix = () => {
    setStepFiveCompleted(true);
};

const handleContinueToSeven = () => {
    setStepSixCompleted(true);
};

const mobileMockupRef = useRef(null);
const handleScrollToMockup = () => {
    if (window.innerWidth < 768 && mobileMockupRef.current) {
      mobileMockupRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

const [showLimits, setShowLimits] = useState(false);

const handleReturn = () => {
    setIsStarted(false);
    setStepOneCompleted(false);
    setStepTwoCompleted(false);
    setStepThreeCompleted(false);
    setStepFourCompleted(false);
    setStepFiveCompleted(false)
    setStepSixCompleted(false);
    setStepSixHalfCompleted(false);
    setStepThreehalfCompleted(false);
    setShowLimits(false);
};

  const handleShowLimits = () => {
    setShowLimits((prevShow) => !prevShow);
  };

    return (

        <div className="main desktopspacing">
            
            <div class="fullscreen-div">
<div class="maintoolcontent">
            <div class={`urlmagnet thefirststep ${stepOneCompleted ? 'fade-out' : ''}`}>
            <div class="titledivv"><h1 class="h1">Start here</h1></div>
            <div class="urlmagnetformdiv"><form className="d-md-flex justify-content-center align-items-center generateform">
                    <div className="form-group me-0 me-md-3 ">
                    <input
                                    placeholder="https://www.yourwebsite.com"
                                    className="form-control yoururl"
                                    name="site_url"
                                    value={url}
                                    onChange={(e) => { setUrl(e.target.value) }}
                                    autocomplete="url"

                                 />
                    </div>

                    <div className="submit-btn mt-md-0 mt-3 ">
                        <button
                            type="button"
                            className="btn urlbtn"
                            onClick={handleStartAndGenerateMockup}
                        >
                            <span className={`btn-text`}>
                                Start
                            </span>

                        </button>
                    </div>
                </form></div>

            </div>
            <div class={`thesecondstep ${stepOneCompleted ? 'fade-in' : ''} ${stepTwoCompleted ? 'steptwocompleted' : ''}`}>
                <h1 class="h1 secondsteptitle">Customize</h1>
                <div className="columnstwo">
                <div className="settingsselect settingsselectc">
                    {/* Language Dropdown */}<div>‎</div>
                    <div classname="generateselectdiv">
                    <div className="custom-dropdown selectcm  generateselect" onClick={() => toggleDropdown("campaign")}>
                        <div className="custom-dropdown-selected">
                            {getDisplayLanguage()}
                            <svg className="custom-dropdown-chevron" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                <path fill="white" d="M7 10l5 5 5-5z" />
                            </svg>
                        </div>
                        {dropdownOpen.campaign && (
                            <ul className="custom-dropdown-options custom-dropdown-optionsc">
                                {["English", "Spanish", "French", "Dutch", "German"].map((language) => (
                                    <li key={language} className="custom-dropdown-option custom-dropdown-optionc" onClick={(e) => handleOptionSelect("campaign", language, e)}>
                                        {language}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>
                    </div>
                    {/* Cialdini's Principle Dropdown */}
                    <div classname="generateselectdiv cialdinidiv">
                    <div className="custom-dropdown selectcp  generateselect" onClick={() => toggleDropdown("principle")}>
                        <div className="custom-dropdown-selected">
                            {getDisplayPrinciple()}
                            <svg className="custom-dropdown-chevron" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                                <path fill="white" d="M7 10l5 5 5-5z" />
                            </svg>
                        </div>
                        {dropdownOpen.principle && (
                            <ul className="custom-dropdown-options custom-dropdown-optionsc">
                                {["Reciprocity", "Consistency", "Social Proof", "Authority", "Liking", "Scarcity", "None"].map((option) => (
                                    <li key={option} className="custom-dropdown-option" onClick={(e) => handleOptionSelect("principle", option, e)}>
                                        {option}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div></div>
                    <div>‎</div>
                    <button className="btn steptwobtn" onClick={handleContinue}>Continue</button>
                </div>
                <div class="txtar">
                    <div className="dynamic-textarea-wrapper">
                        {/* Navigation Menu for Special Instructions */}
                        
                        <div className="special-instructions-menu special-instructions-menunew">
                            {["Headlines", "Descriptions", "Sitelinks", "Callouts", "Snippets", "Keywords"].map((item) => (
                                <button
                                    key={item}
                                    className={`menubtnnew menu-btn ${selectedMenu === item ? "selected" : ""}`}
                                    onClick={() => setSelectedMenu(item)}
                                >
                                    {item}
                                </button>
                            ))}
                        </div>

                        {/* Textarea for Special Instructions */}
                        <div className="textarea-container txtnewi">
                            
                            <div className="tooltip-container tooltip-container1">
                                <span className="question-mark question-mark1">?</span>
                                <div className="tooltip-text tooltip-text1">
                                    <span className="tool-tip-title">Tips:</span>
                                    <ul>
                                        <li>- Maintain the prompt structure</li>
                                        <li>- Avoid removal of character limits</li>
                                        <li>- Empty to exclude from download</li>
                                    </ul>
                                </div>
                            </div>
                            <textarea
                                className="form-control custominform dynamic-textarea instructionsnew"
                                onFocus={() => setShowCustomInstructions(true)}
                                rows="4"
                                value={campaignData[campaignMode].menuItems[selectedMenu] || ""}
                                onChange={handleInstructionsChange}
                                placeholder={`Leave empty to skip ${selectedMenu}`}
                            />
                            <div className="charactercountert">
                                {campaignData[campaignMode].menuItems[selectedMenu]?.length || 0}/{maxInstructionsLength}
                            </div>
                        </div>
                    </div>
                </div></div>
            </div>
            <div class={`thethirdstep ${stepTwoCompleted ? 'fade-in' : ''} ${stepThreeCompleted ? 'fade-out' : ''}`}>
            <h1 class="h1 thirdsteptitle">Extract</h1>
            {isAuthenticated ? (
  <div className="extractcost extractcost2">
    <p className="p-16 moresettingstext nextinstead" style={{ marginLeft: "15px" }}>
      Estimated cost: <span style={{ color: "white" }}>{totalCost || 250} credits</span>
      <div className="tooltip-container">
        <span className="question-mark">?</span>
        <div className="tooltip-text">
          Credits are the in-app currency you spend to generate, refine, and download ad campaigns.
        </div>
      </div>
    </p>
  </div>
) : null}
            <div className="thirdsettingsline amountscc extractforms">
                        {/* Amount of Ads Input */}
                        <div className="label-sc1 label-sc1c align-items-center" style={{ fontSize: "14px" }}>
                            <label htmlFor="number_of_ads" className="me-2 amountofadsc2">Amount of ads</label>

                            <input placeholder="Enter number" className="amountofads realamount amountofadsc" type="number" id="number_of_ads" name="number_of_ads"
                                value={numberOfAds}
                                onChange={(e) => setNumberOfAds(Math.max(1, Number(e.target.value) || 1))}
                                min="1" max="5"
                            />
                            <div class="controlinputc"><span className="newestminus minusc minus-sign minus-sign-amount" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfAds((prev) => Math.max(1, prev - 1))}>-</span>
                                <span className="plus-sign plussignc newestplus" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfAds((prev) => Math.min(5, prev + 1))}>+</span></div>
                        </div>

                        {/* Amount of Keywords Input */}
                        <div className="label-keywords label-sc1 label-sc1c align-items-center" style={{ fontSize: "14px", marginTop: "10px" }}>
                            <label htmlFor="number_of_keywords" className="me-2 amountofkeywordsc">Amount of keywords</label>

                            <input placeholder="Enter number" className="amountofads amountofadsc" type="number" id="number_of_keywords" name="number_of_keywords"
                                value={numberOfKeywords}
                                onChange={(e) => setNumberOfKeywords(Math.max(5, Number(e.target.value) || 5))}
                                min="5" max="20"
                            />
                            <div class="controlinputc2"><span className="plussignc plus-sign newestplus2" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfKeywords((prev) => Math.min(20, prev + 5))}>+</span>
                                <span className="newestminus2 minusc minus-sign mmam" onMouseDown={(e) => e.preventDefault()} onClick={() => setNumberOfKeywords((prev) => Math.max(5, prev - 5))}>-</span>
                            </div>
                        </div>
                        </div>
            <button className="btn stepthreebutton"onClick={() => (isAuthenticated ? (handleExtract(), handleContinueToFour(), handleContinueToFive()) : handleContinueToFour())}>{isAuthenticated ? "Download" : "Continue"}</button>
            </div>
            <div class={`thefourthstep ${stepThreeCompleted ? 'fade-in' : ''} ${stepFourCompleted ? 'fade-out' : ''}`}>
            <h1 class="h1 fourthsteptitle">{stepThreehalfCompleted ? "Success" : "Download"}</h1>
            
            <div class={`authdiv ${stepThreehalfCompleted ? 'fade-out' : ''}`}>
                <p class="authlocksentence">To download your campaign, create an account:</p>
            <Authwall onAuthSuccess={handleAuthSuccess} />
            </div>
           
            <div class={`whatsnextdiv ${stepThreehalfCompleted ? 'fade-in' : ''}`}>
            <button className="btn stepthreebutton" onClick={() => { handleExtract(); handleContinueToFive(); }}>Download</button>
            <div className="extractcost extractcost3">
    <p className="p-16 moresettingstext nextinstead" style={{ marginLeft: "15px" }}>
      Estimated cost: <span style={{ color: "white" }}>{totalCost || 250} credits</span>
      <div className="tooltip-container">
        <span className="question-mark">?</span>
        <div className="tooltip-text">
          Credits are the in-app currency you spend to generate, refine, and download ad campaigns.
        </div>
      </div>
    </p>
  </div>
            </div>
            </div>
            <div class={`thefifthstep thesixststep ${stepFourCompleted ? 'fade-in' : ''}`}>
            <h1 class="h1 fourthsteptitle">Import</h1>
            
            <p class={`fourthstepdesc ${stepSixCompleted ? 'fade-out' : ''}`}><span className={`${extractLoading ? 'extract-loading-animation' : ''}`}>{extractFile ? 'Campaign downloaded.' : 'Downloading campaign...'}</span>{extractFile ? " What's next:" : " While you wait:"}</p>
                <div class={`columnsz ${stepSixCompleted ? 'fade-out' : ''}`}>
                    <div class="columnonee">
    <img class="smallimgg"src="/images/googleeditor.png" alt="Google Editor" />
                    </div>
                    
                    <div class="columntwoo">
                    <p>Open the Google ads Editor</p>
                    <p class="secondpma">or install it <a class="linknostyle" href="https://ads.google.com/home/tools/ads-editor/" target="_blank">here</a>.</p>
                    </div>
                    </div>
             

                <div class={`columnsz columnsz2 ${stepFiveCompleted ? 'fade-in' : ''} ${stepSixCompleted ? 'fade-out' : ''}`}>
                    <div class="columnonee">
                    <img class="smallimgg"src="/images/excelogo.png" alt="Excel Logo" />
                    </div>
                    
                    <div class="columntwoo">
                    <p>Shorten the cells highlighted red.</p>
                    <p class="secondpma">View character limits <a class="linknostyle"onClick={handleShowLimits}>here</a>.</p>
                    
                    <div class={`characterlimits ${showLimits ? 'fade-in' : 'fade-out'}`}>
                        
                        <table className="listoe" style={{ width: '100%', borderCollapse: 'collapse' }}>
          <tbody>
            <tr>
              <td>Headlines</td>
              <td> ‎  ‎  ‎  ‎  ‎  ‎ 30 characters</td>
            </tr>
            <tr>
              <td>Descriptions</td>
              <td> ‎  ‎  ‎  ‎  ‎  ‎ 90 characters</td>
            </tr>
            <tr>
              <td>Sitelink text</td>
              <td> ‎  ‎  ‎  ‎  ‎  ‎ 25 characters</td>
            </tr>
            <tr>
              <td>Sitelink desc</td>
              <td> ‎  ‎  ‎  ‎  ‎  ‎ 35 characters</td>
            </tr>
            <tr>
              <td>Callouts</td>
              <td> ‎  ‎  ‎  ‎  ‎  ‎ 30 characters</td>
            </tr>
          </tbody>
        </table>
                    </div>
                    </div>
                    
                </div>

                <div class={`columnsz columnsz3 ${stepSixHalfCompleted ? 'fade-in' : ''} ${stepSixCompleted ? 'fade-out' : ''}`}>
                    <div class="columnonee">
                    <img class="smallimgg"src="/images/pdfloogo.png" alt="PDF Logo" />
                    </div>
                    
                    <div class="columntwoo">
                    <p>If you need further assistance</p>
                    <p class="secondpma"><a class="linknostyle" href="/images/Launch a Campaign in 60 seconds.pdf" target="_blank">Download</a> the walkthrough.</p>
                    </div></div>

                <div class={`stepseven ${stepSixCompleted ? 'fade-in' : ''}`}>
                            <p>For each of the titles in the sheet:</p>
                            <ul class="listoee">
    <li>1. ‎  Head to the title name</li>
    <li>2. ‎  Click on make multiple changes</li>
    <li>3. ‎  Paste and process</li>
    <li>4. ‎  Keep all changes</li>
</ul>
                </div>

            <button className={`btn stepfourbutton thisoneriht ${stepFiveCompleted ? 'hideelement' : ''}`} onClick={handleContinueToSix}>Next</button>
            <button className={`btn stepfourbutton thisoneriht thefifthstep stepfivebutton stepsixhalfbtn ${stepSixHalfCompleted ? 'fade-in' : ''} ${stepSixCompleted ? 'nodisplayimportant' : ''}`} onClick={handleReturn}>Finish</button>
            <button className={`btn stepfourbutton thisoneriht thefifthstep stepfivebutton ${stepFiveCompleted ? 'fade-in' : ''} ${stepSixHalfCompleted ? 'nodisplayimportant' : ''}`} onClick={handleContinueToSixHalf}>Next</button>
            <button className={`btn stepsevenbtn ${stepSixCompleted ? 'specialfade' : ''}`} onClick={handleReturn}>Return</button>
            <span class={`needmorehelp ${stepSixCompleted ? 'fade-in' : ''}`}>or visit the full import guide here</span>
            <div class={`thegif ${stepSixCompleted ? 'fade-in' : ''}`}>

            </div>
            </div>
<div class={`glowy ${stepOneCompleted ? 'glowfirst' : ''}`}>
            <div className="d-md-block d-none">
                     <BackdropAnimation glowClass={glowClass} delay={1.5} />
                  </div>
                  <div className="d-md-none d-block">
                     <BackdropGlow />
                  </div></div>
                  <AnimatedSection delay={0.2}>
            <div className={`desktop_mockup nomobile desktopmock ${isStarted ? 'started' : ''} ${stepThreeCompleted ? 'fade-out' : ''}`}>
                <div className="google_interface google_interface_desktop">

                    <div className="google_serach_result">
                        <div className="sponsored-text2">
                            <span>Sponsored</span>
                        </div>
                        <div className="serach_item">
                            <div className="brand_logo_img">
                                <img src="images/smalllogo.png" alt="" />
                            </div>
                            <div className="brand_info">
                                <div className="barnd_name">
                                    <h6 className={`m-0 ${mockupLoading ? 'loading-animation' : ''}`}>
                                        {mockup?.display_url
                                            ? mockup?.display_url
                                            : "ppcc.ai"}
                                    </h6>
                                    <div className="link">
                                        <p className={`website_link ${mockupLoading ? 'loading-animation' : ''}`}>
                                            {mockup?.url
                                                ? mockup?.url
                                                : "# "}
                                        </p>
                                        <a href="#" className="modal_dot">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serach_result_main">
                            <a
                                href="#"
                                className=""

                                onclick="return false;"
                            >
                                <h1 className={`${mockupLoading ? 'loading-animation' : ''}`}>
                                    {mockup?.headline
                                        ? mockup?.headline
                                        : " PPCC - Generate Google Ads - Try for Free "}
                                </h1>
                            </a>
                            <p className={`info ${mockupLoading ? 'loading-animation' : ''}`}>
                                {mockup?.description
                                    ? mockup?.description
                                    : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                            </p>
                        </div>
                        {mockup?.sitelinks?.length >= 0
                            ? mockup?.sitelinks?.map((item, index) => (
                                <div className={`Ktlw8e ${mockupLoading ? 'loading-animation' : ''}`} key={index}>
                                    <div className="iCzAIb">
                                        <div className="d-flx mock-site-link">
                                            <a href="#" className="" onclick="return false;">{item.title}</a>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                        <p className="info m-0">{item.description}</p>
                                    </div>
                                </div>
                            ))
                            : Mocksite?.map((item, index) => (
                                <div className={`Ktlw8e ${mockupLoading ? 'loading-animation' : ''}`} key={index}>
                                    <div className="iCzAIb">
                                        <div className="d-flx mock-site-link">
                                            <a className="" onclick="return false;">{item.site_text}</a>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                        <p className="info m-0">{item.sitePara}...</p>
                                    </div>
                                </div>
                            ))}


                    </div>
                </div>
            </div></AnimatedSection>
            
            <div className={`desktop_mockup mobile_mockup mobilemock ${stepTwoCompleted ? 'mobilemockupduringstepthree' : ''} ${stepThreeCompleted ? 'mobilemockupduringstepfour' : ''} `}>
                <div className="google_interface google_interface_mobile">
                    <div className="header_area_mobile">
                        <div className="header_ser_mobile">
                            <div className="serach_wapper">
                                <div className="google_logo_mobile">
                                    <img src="images/Google_2015_logo2.png" alt="" height="40px" />
                                </div>
                                <div className="gmail gmailmobile">
                                    <img src="images/smalllogo.png" alt="" />
                                </div>
                                <div className="google_serach google_serach_mobile">
                                    <div className="ser">

                                        <button href="#" className="goog_btn serch_ico serch_icomobile">
                                            <img src="images/Vector_search_icon.svg.png" alt="" />
                                        </button>

                                        <input className={`google_serach_input google_serach_input_mobile p-0 ${mockupLoading ? 'loading-animation' : ''}`}
                                            type="text"
                                            value={mockup.url ? formatUrl(mockup.url) : "brandname"}
                                        />
                                        <div className="google-search-close me-2">
                                            <svg
                                                focusable="false"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="end_area">
                                        <button href="#" className="goog_btn mic_mobile">
                                            <img src="images/Google_mic.svg" alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="right ">
                                {/* <div className="search_labs">
                                             <img src="images/serachlabs.png" alt="" width="25px" />
                                          </div> */}

                            </div>
                        </div>
                        <div className="tabsmobile">
                            <div className="left_tabs_mobile">
                                <button href="#" className="tab_btn_mobile active">All</button>
                                <button href="#" className="tab_btn_mobile">Images</button>
                                <button href="#" className="tab_btn_mobile">Videos</button>
                                <button href="#" className="tab_btn_mobile">News</button>
                                <button href="#" className="tab_btn_mobile">Shopping</button>
                                <button href="#" className="tab_btn_mobile">Maps</button>
                                <button href="#" className="tab_btn_mobile">Books</button>
                                <button href="#" className="tab_btn_mobile d-none">
                                    <span>
                                        <i className="bx bx-dots-vertical-rounded"></i>
                                    </span>
                                    More
                                </button>
                            </div>
                            <div className="right_tabs d-none">
                                <button className="tab_btn ">Tool</button>
                            </div>
                        </div>
                    </div>
                    <div className="capsul_tab_mobile">
                        <button href="#" className="capsul_mobile">Free</button>
                        <button href="#" className="capsul_mobile">Reviews</button>
                        <button href="#" className="capsul_mobile">Open Now</button>
                        <button href="#" className="capsul_mobile">Clips</button>
                        <button href="#" className="capsul_mobile">Pricing</button>
                    </div>
                    <div className="google_serach_result_mobile">
                        <div className="sponsored-text2_mobile">
                            <span>Sponsored</span>
                        </div>
                        <div className="serach_item">
                            <div className="brand_logo_img brand_logo_img_mobile">
                                <img src="images/smalllogo.png" alt="" />
                            </div>
                            <div className="brand_info">
                                <div className="barnd_name_mobile">
                                    <h6 className={`m-0 ${mockupLoading ? 'loading-animation' : ''}`}>
                                        {mockup?.display_url
                                            ? mockup?.display_url
                                            : "yourwebsite.com"}
                                    </h6>
                                    <div className="link">
                                        <p className={`website_link_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                            {mockup?.url
                                                ? mockup?.url
                                                : "#"}
                                        </p>
                                        <a href="#" className="modal_dot_mobile">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serach_result_main_mobile">
                            <a
                                href="#"
                                className=""

                                onclick="return false;"
                            >
                                <h1 className={`${mockupLoading ? 'loading-animation' : ''}`}>
                                    {mockup?.headline
                                        ? mockup?.headline
                                        : " PPCC - Generate Google Ads - Try for Free "}
                                </h1>
                            </a>
                            {/* <span className="brand_name_highlisght">Munch</span> */}
                            <p className={`info_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                {mockup?.description
                                    ? mockup?.description
                                    : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                            </p>
                        </div>
                        {mockup?.sitelinks?.length >= 0
                            ? mockup?.sitelinks?.map((item, index) => (
                                <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''} ${mockupLoading ? 'loading-animation' : ''}`}>
                                    <div className="iCzAIb_mobile">
                                        <div className="d-flx mock-site-link">
                                            <a href="#" className="" onclick="return false;">{item.title}</a><span class="arrowsitelink">›</span>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : Mocksite?.map((item, index) => (
                                <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''} ${mockupLoading ? 'loading-animation' : ''}`}>
                                    <div className="iCzAIb_mobile">
                                        <div className="d-flx mock-site-link">
                                            <a className="" onclick="return false;">{item.site_text}</a>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                        <p className="info m-0">{item.sitePara}</p>
                                    </div>
                                </div>
                            ))}
                    </div>


                    {/* SECOND SEARCH RESULT */}
                    <div className="google_serach_result_mobile google_serach_result_mobile2">

                        <div className="serach_item">
                            <div className="brand_logo_img brand_logo_img_mobile">
                                <img src="images/smalllogo.png" alt="" />
                            </div>
                            <div className="brand_info">
                                <div className="barnd_name_mobile">
                                    <h6 className={`m-0 ${mockupLoading ? 'loading-animation' : ''}`}>
                                        {mockup?.display_url
                                            ? mockup?.display_url
                                            : "ppcc.ai"}
                                    </h6>
                                    <div className="link">
                                        <p className={`website_link_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                            {mockup?.url
                                                ? mockup?.url
                                                : "# "}
                                        </p>
                                        <a href="#" className="modal_dot_mobile">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serach_result_main_mobile">
                            <a
                                href="#"
                                className=""

                                onclick="return false;"
                            >
                                <h1 className={`${mockupLoading ? 'loading-animation' : ''}`}>
                                    {mockup?.headline
                                        ? mockup?.headline
                                        : " PPCC AI - Generate Google Ads - Try for Free "}
                                </h1>
                            </a>
                            {/* <span className="brand_name_highlisght">Munch</span> */}
                            <p className={`info_mobile info_mobile2 ${mockupLoading ? 'loading-animation' : ''}`}>
                                {mockup?.description
                                    ? mockup?.description
                                    : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                            </p>
                            <div className={`snippetdivmobile ${mockupLoading ? 'loading-animation' : ''}`}><a href="#"><span class="snippetmobile">Your</span></a><a href="#"><span class="snippetmobile">Callouts</span></a><a href="#"><span class="snippetmobile">Here</span></a><a href="#"><span class="snippetmobile">Try and See for Free</span></a></div>
                        </div>


                    </div>



                </div>
            </div>

            </div>
            </div>

            <div className="mobilealternative mobilealternativefirstdiv container mobileonly thisoneiscool">
                     <AnimatedSection>
                        <div className="top-title mb-md-5 pb-1 mb-2">WELCOME</div>
                        <div className="desc mb-4">
                           <p className="p-18 generatep generatepanother">Looking to speed up Google search ads campaign creation? You are in the right place.
                           </p>
                        </div>
                     </AnimatedSection></div>

            <div class="threestepsect">
                <AnimatedSection>
                <div class="steponesect sectt">
                <img class="imagesteps stepthreeimg" src="/images/ppclogowhite.png" alt="ppc ads ai generate"></img><br/>
                    <span>Step 1</span>
                    <h2 class="titleinsteps">Customize</h2>
                    <p>Allow AI to generate ads using your website URL. Optionally set a language, Cialdini principle, or customize the prompts directly.</p>
                </div>
                </AnimatedSection>
                <AnimatedSection delay={0.1}>
                <div class="steptwosect sectt">
                <img class="imagesteps stepthreeimg" src="/images/excelogo.png" alt="google ads editor"></img><br/>
                <span>Step 2</span>
                    <h2 class="titleinsteps">Download</h2>
                    <p> Extract your ads and download your campaign as an Excel file. Generate multiple ads or keywords based on your needs.</p>
                </div>
                </AnimatedSection>
                <AnimatedSection delay={0.2}>
                <div class="stepthreesect sectt">
                    <img class="imagesteps stepthreeimg" src="/images/googleeditor.png" alt="google ads editor"></img><br/>
                <span>Step 3</span>
                    <h2 class="titleinsteps">Publish</h2>
                    <p>Import or publish your campaign using the Google ads editor and copy/paste. We are here every step of the way.</p>
                </div>
                </AnimatedSection>
            </div>

            <div class="mobilealternative">
            <div class="mobilealternativefirstdiv">
            
                     <div className="container mobileonly">
                    
                     <AnimatedSection delay={0}>
                        <div className="desc mb-4">
                           <p className="p-18 generatep">Enter your website url below. Please visit on a larger screen to unlock all functionalities.
                           </p>
                        </div>
                     </AnimatedSection>
                        <div className="banner-form-sec mt-md-5 mobilehomesec">
                           <form className="d-md-flex justify-content-center align-items-center generateform">
                              <div className="form-group me-0 me-md-3 ">
                                 <input
                                    placeholder="https://www.yourwebsite.com"
                                    className="form-control"
                                    name="site_url"
                                    value={url}
                                    onChange={(e) => { setUrl(e.target.value) }}
                                    autocomplete="url"

                                 />
                              </div>
                              <div className="submit-btn mt-md-0 mt-3 ">
                                 <button type="button" className="btn " onClick={handleStartAndGenerateMockup}>
                                    Generate ad
                                    <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       viewBox="0 0 256 256"
                                       focusable="false"
                                       color="rgb(255, 255, 255)"
                                    >
                                       <g color="rgb(255, 255, 255)" weight="bold">
                                          <path d="M224.49,136.49l-72,72a12,12,0,0,1-17-17L187,140H40a12,12,0,0,1,0-24H187L135.51,64.48a12,12,0,0,1,17-17l72,72A12,12,0,0,1,224.49,136.49Z"></path>
                                       </g>
                                    </svg>
                                 </button>
                              </div>
                           </form>

                        </div>
                        {isAuthenticated && (
                           <div className="signedinundergenerate"></div>
                        )}

                  </div>

            </div>

            <div class="mobilealternativeseconddiv">
            <div ref={mobileMockupRef} className={`desktop_mockup mobile_mockup mobilemock`}>
                <div className="google_interface google_interface_mobile">
                    <div className="header_area_mobile">
                        <div className="header_ser_mobile">
                            <div className="serach_wapper">
                                <div className="google_logo_mobile">
                                    <img src="images/Google_2015_logo2.png" alt="" height="40px" />
                                </div>
                                <div className="gmail gmailmobile">
                                    <img src="images/smalllogo.png" alt="" />
                                </div>
                                <div className="google_serach google_serach_mobile">
                                    <div className="ser">

                                        <button href="#" className="goog_btn serch_ico serch_icomobile">
                                            <img src="images/Vector_search_icon.svg.png" alt="" />
                                        </button>

                                        <input className={`google_serach_input google_serach_input_mobile p-0 ${mockupLoading ? 'loading-animation' : ''}`}
                                            type="text"
                                            value={mockup.url ? formatUrl(mockup.url) : "brandname"}
                                        />
                                        <div className="google-search-close me-2">
                                            <svg
                                                focusable="false"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
                                            </svg>
                                        </div>
                                    </div>
                                    <div className="end_area">
                                        <button href="#" className="goog_btn mic_mobile">
                                            <img src="images/Google_mic.svg" alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="right ">
                                {/* <div className="search_labs">
                                             <img src="images/serachlabs.png" alt="" width="25px" />
                                          </div> */}

                            </div>
                        </div>
                        <div className="tabsmobile">
                            <div className="left_tabs_mobile">
                                <button href="#" className="tab_btn_mobile active">All</button>
                                <button href="#" className="tab_btn_mobile">Images</button>
                                <button href="#" className="tab_btn_mobile">Videos</button>
                                <button href="#" className="tab_btn_mobile">News</button>
                                <button href="#" className="tab_btn_mobile">Shopping</button>
                                <button href="#" className="tab_btn_mobile">Maps</button>
                                <button href="#" className="tab_btn_mobile">Books</button>
                                <button href="#" className="tab_btn_mobile d-none">
                                    <span>
                                        <i className="bx bx-dots-vertical-rounded"></i>
                                    </span>
                                    More
                                </button>
                            </div>
                            <div className="right_tabs d-none">
                                <button className="tab_btn ">Tool</button>
                            </div>
                        </div>
                    </div>
                    <div className="capsul_tab_mobile">
                        <button href="#" className="capsul_mobile">Free</button>
                        <button href="#" className="capsul_mobile">Reviews</button>
                        <button href="#" className="capsul_mobile">Open Now</button>
                        <button href="#" className="capsul_mobile">Clips</button>
                        <button href="#" className="capsul_mobile">Pricing</button>
                    </div>
                    <div className="google_serach_result_mobile">
                        <div className="sponsored-text2_mobile">
                            <span>Sponsored</span>
                        </div>
                        <div className="serach_item">
                            <div className="brand_logo_img brand_logo_img_mobile">
                                <img src="images/smalllogo.png" alt="" />
                            </div>
                            <div className="brand_info">
                                <div className="barnd_name_mobile">
                                    <h6 className={`m-0 ${mockupLoading ? 'loading-animation' : ''}`}>
                                        {mockup?.display_url
                                            ? mockup?.display_url
                                            : "yourwebsite.com"}
                                    </h6>
                                    <div className="link">
                                        <p className={`website_link_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                            {mockup?.url
                                                ? mockup?.url
                                                : "#"}
                                        </p>
                                        <a href="#" className="modal_dot_mobile">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serach_result_main_mobile">
                            <a
                                href="#"
                                className=""

                                onclick="return false;"
                            >
                                <h1 className={`${mockupLoading ? 'loading-animation' : ''}`}>
                                    {mockup?.headline
                                        ? mockup?.headline
                                        : " PPCC - Generate Google Ads - Try for Free "}
                                </h1>
                            </a>
                            {/* <span className="brand_name_highlisght">Munch</span> */}
                            <p className={`info_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                {mockup?.description
                                    ? mockup?.description
                                    : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                            </p>
                        </div>
                        {mockup?.sitelinks?.length >= 0
                            ? mockup?.sitelinks?.map((item, index) => (
                                <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''} ${mockupLoading ? 'loading-animation' : ''}`}>
                                    <div className="iCzAIb_mobile">
                                        <div className="d-flx mock-site-link">
                                            <a href="#" className="" onclick="return false;">{item.title}</a><span class="arrowsitelink">›</span>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                            : Mocksite?.map((item, index) => (
                                <div className={`Ktlw8e_mobile ${index === Mocksite.length - 1 ? 'sitelinklast' : ''} ${mockupLoading ? 'loading-animation' : ''}`}>
                                    <div className="iCzAIb_mobile">
                                        <div className="d-flx mock-site-link">
                                            <a className="" onclick="return false;">{item.site_text}</a>
                                            <div className="icon d-none-icon">
                                                <i className="bx bx-chevron-right"></i>
                                            </div>
                                        </div>
                                        <p className="info m-0">{item.sitePara}</p>
                                    </div>
                                </div>
                            ))}
                    </div>


                    {/* SECOND SEARCH RESULT */}
                    <div className="google_serach_result_mobile google_serach_result_mobile2">

                        <div className="serach_item">
                            <div className="brand_logo_img brand_logo_img_mobile">
                                <img src="images/smalllogo.png" alt="" />
                            </div>
                            <div className="brand_info">
                                <div className="barnd_name_mobile">
                                    <h6 className={`m-0 ${mockupLoading ? 'loading-animation' : ''}`}>
                                        {mockup?.display_url
                                            ? mockup?.display_url
                                            : "ppcc.ai"}
                                    </h6>
                                    <div className="link">
                                        <p className={`website_link_mobile ${mockupLoading ? 'loading-animation' : ''}`}>
                                            {mockup?.url
                                                ? mockup?.url
                                                : "# "}
                                        </p>
                                        <a href="#" className="modal_dot_mobile">
                                            <i className="bx bx-dots-vertical-rounded"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="serach_result_main_mobile">
                            <a
                                href="#"
                                className=""

                                onclick="return false;"
                            >
                                <h1 className={`${mockupLoading ? 'loading-animation' : ''}`}>
                                    {mockup?.headline
                                        ? mockup?.headline
                                        : " PPCC AI - Generate Google Ads - Try for Free "}
                                </h1>
                            </a>
                            {/* <span className="brand_name_highlisght">Munch</span> */}
                            <p className={`info_mobile info_mobile2 ${mockupLoading ? 'loading-animation' : ''}`}>
                                {mockup?.description
                                    ? mockup?.description
                                    : "extracts the most engaging, trending and impactful clips from your long-form videosis centered around machine learning capabilities, designed to keep what's important."}...
                            </p>
                            <div className={`snippetdivmobile ${mockupLoading ? 'loading-animation' : ''}`}><a href="#"><span class="snippetmobile">Your</span></a><a href="#"><span class="snippetmobile">Callouts</span></a><a href="#"><span class="snippetmobile">Here</span></a><a href="#"><span class="snippetmobile">Try and See for Free</span></a></div>
                        </div>


                    </div>



                </div>
            </div>
            </div>
            </div>
            
        </div>
    );
};
export default Homepage;
